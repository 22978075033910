import React from 'react';
import { connect } from 'react-redux';
import { view, lensProp } from 'ramda';
import { successMessage, errorMessage } from './state';

const flashLens = lensProp('flash');
const state = view(flashLens);

const flashClass = message =>
  typeof message === 'string' ? 'flash flash--visible' : 'flash';

const messageClass = (message, type) =>
  typeof message === 'string'
    ? 'flash__message flash__message--' + type
    : 'flash__message';

const Flash = connect(state)(({ message, type }) => (
  <div className={flashClass(message)}>
    <div className={messageClass(message, type)}>{message}</div>
  </div>
));

export { Flash, successMessage, errorMessage };
