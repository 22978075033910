import { createReducer } from '/util/createReducer';
import { lensProp, set, compose } from 'ramda';

const state = {
  from: 0,
  to: 0
};

const forstegangsjegerLens = lensProp('forstegangsjeger');
const from = lensProp('from');
const to = lensProp('to');

const reducers = {
  FORSTEGANGSJEGER_FROM: (state, action) => set(from, action.payload, state),
  FORSTEGANGSJEGER_TO: (state, action) => set(to, action.payload, state),
  FORSTEGANGSJEGER_RESPONSE: (state, action) =>
    compose(set(from, action.payload.from), set(to, action.payload.to))(state)
};

const forstegangsjeger = createReducer(state, reducers);

export { forstegangsjeger, forstegangsjegerLens };
