import React from 'react';
import { Fragment } from 'redux-little-router';
import { Login } from '/login';
import { LoggedIn } from '/loggedin';
import { Home } from '/home';
import { Statusmelding } from '/statusmelding';
import { Forstegangsjeger } from '/forstegangsjeger';
import { Discounts } from '/discount';
import { Flash } from '/flash';

const App = () => (
  <Fragment forRoute="/">
    <React.Fragment>
      <Flash />
      <Fragment forRoute="/login">
        <Login />
      </Fragment>
      <Fragment forRoute="/home">
        <LoggedIn>
          <Fragment forRoute="/statusmelding">
            <Statusmelding />
          </Fragment>
          <Fragment forRoute="/forstegangsjeger">
            <Forstegangsjeger />
          </Fragment>
          <Fragment forRoute="/discount">
            <Discounts />
          </Fragment>
          <Fragment forRoute="/">
            <Home />
          </Fragment>
        </LoggedIn>
      </Fragment>
      <Fragment forNoMatch>
        <div>Laster...</div>
      </Fragment>
    </React.Fragment>
  </Fragment>
);

export { App };
