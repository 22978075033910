import React from 'react';
import { Link } from 'redux-little-router';
import './index.css';
import { connect } from 'react-redux';
import { lensProp, view } from 'ramda';
import { logout } from '/api';
import { push } from 'redux-little-router';

const doLogout = dispatch => {
  dispatch({ type: 'LOGGED_OUT' });
  dispatch(push('/login'));
};

const logoutAction = () => dispatch =>
  logout()
    .then(() => doLogout(dispatch))
    .catch(() => doLogout(dispatch));

const userLens = lensProp('user');
const state = view(userLens);

const actions = dispatch => ({
  logout: () => dispatch(logoutAction())
});

const normalClass = 'sidebar__menu-link';
const activeClass = 'sidebar__menu-link--active';

const MenuItem = ({ href, children }) => (
  <Link
    className={normalClass}
    href={href}
    activeProps={{ className: `${normalClass} ${activeClass}` }}
  >
    {children}
  </Link>
);

const Sidebar = connect(state, actions)(({ name, logout }) => (
  <nav className="app__sidebar sidebar">
    <div className="sidebar__top">
      <Link className="sidebar__logo" href="/">
        Admin
      </Link>
      <div className="sidebar__user">
        {name}&nbsp;
        <button onClick={logout} className="link-button">
          (logg ut)
        </button>
      </div>
    </div>
    <ul className="sidebar__menu">
      <li className="sidebar__menu-item">
        <MenuItem href="/home">Hjem</MenuItem>
      </li>
      <li className="sidebar__menu-item">
        <MenuItem href="/home/statusmelding">Statusmelding</MenuItem>
      </li>
      <li className="sidebar__menu-item">
        <MenuItem href="/home/forstegangsjeger">Førstegangsjeger</MenuItem>
      </li>
      <li className="sidebar__menu-item">
        <MenuItem href="/home/discount">Rabatter</MenuItem>
      </li>
    </ul>
  </nav>
));

export { Sidebar };
