import React from 'react';
import { connect } from 'react-redux';
import view from 'ramda/src/view';
import lensProp from 'ramda/src/lensProp';
import './index.css';
import { login } from '/api';
import { push } from 'redux-little-router';

const loginAction = (username, password) => dispatch =>
  login(username, password)
    .then(res => res.json())
    .then(user => dispatch({ type: 'LOGGED_IN', user }))
    .then(() => dispatch(push('/home')))
    .catch(() => dispatch({ type: 'LOGGED_OUT' }));

const userLens = lensProp('user');
const state = view(userLens);
const actions = dispatch => ({
  login: (username, password) => dispatch(loginAction(username, password)),
  setUsername: val => dispatch({ type: 'USERNAME', val }),
  setPassword: val => dispatch({ type: 'PASSWORD', val })
});

const Login = connect(state, actions)(
  ({ loginState, username, password, login, setUsername, setPassword }) => (
    <form className="login">
      <div className="login__username">
        <label className="label login__label">Brukernavn</label>
        <input
          className="input login__input"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
      </div>
      <div className="login__password">
        <label className="label login__label">Passord</label>
        <input
          type="password"
          className="input login__input"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </div>
      <button
        type="submit"
        onClick={e => {
          e.preventDefault();
          login(username, password);
        }}
        className="button login__button"
      >
        Logg inn
      </button>
    </form>
  )
);

export { Login };
