import { lensPath, view } from 'ramda';
import { LOCATION_CHANGED } from 'redux-little-router';
import { get } from '/http';

const request = lensPath(['result', 'request']);

function replace(url, params) {
  const match = /:[a-zA-Z]+/.exec(url);
  if (match) {
    return match.reduce(
      (acc, cur) => acc.replace(cur, params[cur.slice(1)]),
      url
    );
  } else {
    return url;
  }
}

const fetcher = store => next => action => {
  if (action.type === LOCATION_CHANGED) {
    const req = view(request, action.payload);
    if (req) {
      const params = action.payload.params || {};
      const url = replace(req.url, params);
      // const url = /:[a-zA-Z]+/
      //   .exec(req.url)
      //   .reduce((acc, cur) => acc.replace(cur, params[cur.slice(1)]), req.url);
      store.dispatch(dispatch => {
        get(url)
          .then(res => res.json())
          .then(messages => dispatch({ type: req.success, payload: messages }));
      });
    }
  }

  return next(action);
};

export { fetcher };
