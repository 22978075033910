import './index.css';
import { createReducer } from '/util/createReducer';
import { lensProp, set, compose } from 'ramda';

const state = {
  message: null,
  type: null
};

const message = lensProp('message');
const type = lensProp('type');

const performLater = (fn, time) => {
  setTimeout(fn, time);
};

const setMessage = (newMessage, newType) =>
  compose(set(message, newMessage), set(type, newType));

const reducers = {
  SUCCESS: (state, action) => setMessage(action.val, 'SUCCESS')(state),
  ERROR: (state, action) => setMessage(action.val, 'ERROR')(state),
  HIDE: state => setMessage(null, null)(state)
};

const errorMessage = (_message, duration) => dispatch => {
  dispatch({ type: 'ERROR', val: _message });
  setTimeout(() => {
    dispatch({ type: 'HIDE' });
  }, duration);
};

const successMessage = (_message, duration) => dispatch => {
  dispatch({ type: 'SUCCESS', val: _message });
  setTimeout(() => {
    dispatch({ type: 'HIDE' });
  }, duration);
};

const flash = createReducer(state, reducers);

export { flash, successMessage, errorMessage };
