import React from 'react';
import { render } from 'react-dom';
import { App } from '/app';
import { compose, createStore, applyMiddleware } from 'redux';
import { thunkMiddleware } from '/util/thunkMiddleware';
import { fetcher } from '/util/fetcherMiddleware';
import { devToolsEnhancer } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { reducers, router } from '/state';
import { initializeCurrentLocation, push } from 'redux-little-router';
import { userInfo } from '/api';
import './app.css';

function getStore() {
  const composedMiddleware = [
    applyMiddleware(thunkMiddleware, fetcher, router.middleware)
  ];

  const store = createStore(
    reducers,
    compose(router.enhancer, ...composedMiddleware, devToolsEnhancer())
  );

  if (module.hot) {
    module.hot.accept('./state', () => {
      const nextRootReducer = require('./state');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

// We need to dispatch location explicitly to load any URLs
const store = getStore();
const initialLocation = store.getState().router;
if (initialLocation) {
  store.dispatch(initializeCurrentLocation(initialLocation));
}

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#app')
);

userInfo()
  .then(res => res.json())
  .then(user => store.dispatch({ type: 'LOGGED_IN', user }))
  .then(() => {
    if (!store.getState().router.route) {
      store.dispatch(push('/home'));
    }
  })
  .catch(() => store.dispatch(push('/login')));
