import './index.css';
import React from 'react';
import { connect } from 'react-redux';
import { view, compose } from 'ramda';
import { lenses } from '/state';
import parse from 'date-fns/fp/parse';
import format from 'date-fns/fp/format';

const parseBuildNumber = parse(new Date())('yyyyMMddHHmm');
const formatBuildNumber = format('HH:mm, dd.MM.yyyy');
const buildNumberToString = compose(formatBuildNumber, parseBuildNumber);

const state = view(lenses.info);

const Info = connect(state)(({ environment, buildNumber }) => (
  <dl className="info">
    <dt className="info__key">Miljø:</dt>
    <dd className="info__value">{environment}</dd>
    <dt className="info__key">Prodsatt:</dt>
    <dd className="info__value">{buildNumberToString(buildNumber)}</dd>
  </dl>
));

export { Info };
