import React from 'react';
import './index.css';
import { Box } from '/box';

const tools = [
  {
    name: 'Coralogix',
    url:
      'https://addons-sso.heroku.com/apps/984aba54-cbbd-4474-9d20-72438454ca12/addons/5a1f09e2-4b18-4e8a-a2b6-034a8ac1a08e'
  },
  {
    name: 'New Relic',
    url:
      'https://addons-sso.heroku.com/apps/984aba54-cbbd-4474-9d20-72438454ca12/addons/abe523fe-ce1e-48da-8f4a-67aae0621df2'
  }
];

const services = [
  { name: 'Heroku', url: 'https://dashboard.heroku.com/apps' },
  { name: 'WebSolr', url: 'https://websolr.com/' }
];

const Item = listname => ({ name, url }, id) => (
  <li className="home__item" key={`${listname}-${id}`}>
    <a href={url} target="_blank">
      {name}
    </a>
  </li>
);

const List = ({ items, name }) => (
  <ul className="home__list">{items.map(Item(name))}</ul>
);

const Home = () => (
  <Box title="Hjem">
    <div className="home">
      <div className="home__tools">
        <List items={tools} name="tools" />
        <List items={services} name="services" />
      </div>
    </div>
  </Box>
);

export { Home };
