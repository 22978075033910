import { createReducer } from '/util/createReducer';
import { lensProp, set, compose } from 'ramda';

const state = {
  loggedIn: false,
  username: '',
  password: '',
  name: ''
};

const loggedIn = lensProp('loggedIn');
const username = lensProp('username');
const password = lensProp('password');
const name = lensProp('name');

const reducers = {
  LOGGED_OUT: (state, action) => set(loggedIn, false, state),
  LOGGED_IN: (state, action) =>
    compose(set(loggedIn, true), set(name, action.user.name))(state),
  USERNAME: (state, action) => set(username, action.val, state),
  PASSWORD: (state, action) => set(password, action.val, state)
};

const user = createReducer(state, reducers);

export { user, loggedIn };
