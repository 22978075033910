import propOr from 'ramda/src/propOr';
import identity from 'ramda/src/identity';

const identityReducer = action => state => state;

function createReducer(initialState, handlers) {
  return (state = initialState, action) =>
    propOr(identity, action.type, handlers)(state, action);
}

function functionalReducer(initialState, handlers) {
  return (state = initialState, action) =>
    propOr(identityReducer, action.type, handlers)(action)(state);
}

export { createReducer, functionalReducer };
