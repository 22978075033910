import { functionalReducer } from '/util/createReducer';
import { lensProp, set } from 'ramda';

const state = {
  discounts: [],
  selected: null
};

const discountsLens = lensProp('discounts');
const selected = lensProp('selected');

const reducers = {
  DISCOUNT_RESPONSE: action => set(discountsLens, action.payload),
  DISCOUNT_SELECTED: action => set(selected, action.payload)
};

const discounts = functionalReducer(state, reducers);
export { discounts };
