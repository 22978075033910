import './index.css';
import React from 'react';
import { connect } from 'react-redux';
import { view } from 'ramda';
import { lenses } from '/state';
import { forstegangsjeger } from '/api';
import { successMessage, errorMessage } from '/flash';
import { Box } from '/box';

function validNumber(n) {
  const number = parseInt(n);
  const isNumber = !isNaN(number);
  return isNumber && number >= 0;
}

const save = (from, to) => dispatch =>
  forstegangsjeger(from, to)
    .then(res => res.json())
    .then(payload => dispatch({ type: 'FORSTEGANGSJEGER_RESPONSE', payload }))
    .then(() => successMessage('Success', 2000)(dispatch))
    .catch(e => errorMessage(`Failed: ${e.message}`, 5000)(dispatch));

const state = view(lenses.forstegangsjeger);
const actions = dispatch => ({
  setFrom: payload => {
    if (validNumber(payload)) {
      dispatch({ type: 'FORSTEGANGSJEGER_FROM', payload: parseInt(payload) });
    }
  },
  setTo: payload => {
    if (validNumber(payload)) {
      dispatch({ type: 'FORSTEGANGSJEGER_TO', payload: parseInt(payload) });
    }
  },
  saveAction: (from, to) => dispatch(save(from, to))
});

const Forstegangsjeger = connect(state, actions)(
  ({ from, to, setFrom, setTo, saveAction }) => (
    <Box title="Førstegangsjeger">
      <div className="forstegangsjeger">
        <div>
          Setter jegernummeret som brukes for å bestemme hva som regnes som
          førstegangsjeger og ikke.<br />Jegernummer som er{' '}
          <strong>mellom</strong> disse tallene vil regnes som førstegangsjegere
          på inatur.no
        </div>
        <br />
        <input
          min="0"
          className="input"
          type="number"
          value={from}
          onChange={e => setFrom(e.target.value)}
        />
        {' <='} jegernummer {'< '}
        <input
          min="0"
          className="input"
          type="number"
          value={to}
          onChange={e => setTo(e.target.value)}
        />
        <br />
        <br />
        <button className="button" onClick={() => saveAction(from, to)}>
          Lagre
        </button>
      </div>
    </Box>
  )
);

export { Forstegangsjeger };
