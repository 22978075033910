import React from 'react';
import './index.css';
import { Info } from '/info';

const Main = ({ children }) => (
  <div className="app__main main">
    <div className="main__info">
      <Info />
    </div>
    <div className="main__content">{children}</div>
  </div>
);

export { Main };
