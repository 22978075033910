import './index.css';
import React from 'react';
import { connect } from 'react-redux';
import { lenses } from '/state';
import { view } from 'ramda';
import { Box } from '/box';
import Modal from 'react-modal';
import { kjop } from '/api';

const selectItemAction = id => dispatch =>
  kjop(id)
    .then(res => res.json())
    .then(kjop => dispatch({ type: 'DISCOUNT_SELECTED', payload: kjop }));

const unselectItemAction = () => dispatch =>
  dispatch({ type: 'DISCOUNT_SELECTED', payload: null });

const state = view(lenses.discounts);

const actions = dispatch => ({
  selectItem: id => dispatch(selectItemAction(id)),
  unselectItem: () => dispatch(unselectItemAction())
});

const Discount = selectItem => (discount, key) => (
  <tr key={key}>
    <td className="table__data">{discount.code}</td>
    <td className="table__data">{discount.type}</td>
    <td className="table__data">
      <button
        className="discounts__kjop-button"
        onClick={() => selectItem(discount.kjop)}
      >
        {discount.kjop}
      </button>
    </td>
  </tr>
);

const Discounts = connect(state, actions)(
  ({ discounts, selected, selectItem, unselectItem }) => (
    <Box title="Rabatter (siste 20)">
      <div className="discounts">
        <table className="table">
          <thead>
            <tr className="table__row">
              <th className="table__header">Kode</th>
              <th className="table__header">Type</th>
              <th className="table__header">Kjøp</th>
            </tr>
          </thead>
          <tbody>{discounts.map(Discount(selectItem))}</tbody>
        </table>
        <Modal isOpen={selected != null} ariaHideApp={false}>
          <strong>Rå informasjon om kjøpet rett fra databasen</strong>
          <button className="modal__close" onClick={() => unselectItem()}>
            ✕
          </button>
          <pre>{JSON.stringify(selected, null, 2)}</pre>
        </Modal>
      </div>
    </Box>
  )
);

export { Discounts };
