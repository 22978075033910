import { get, post, put } from '/http';

const api = path => `/api/v1/admin${path}`;

function info() {
  return get(api('/info'));
}

function login(username, password) {
  return post(api('/authentication/login'), { username, password });
}

function logout() {
  return get(api('/authentication/logout'));
}

function userInfo() {
  return get(api('/authentication'));
}

function statusmelding() {
  return get(api('/statusmelding'));
}

function forstegangsjeger(from, to) {
  return typeof from != 'undefined'
    ? put(api('/innstillinger/forstegangsjeger'), { from, to })
    : get(api('/innstillinger/forstegangsjeger'));
}

function kjop(id) {
  return get(api(`/kjop/${id}`));
}

export { info, login, userInfo, logout, forstegangsjeger, kjop };
