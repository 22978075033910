import React from 'react';
import { connect } from 'react-redux';
import { statusmeldingLens } from '/state/statusmelding';
import './index.css';
import view from 'ramda/src/view';
import format from 'date-fns/fp/format';
import { Box } from '/box';

const formatDate = format('dd.MM.yyyy, HH:mm');

const Melding = ({ message, start, end }, key) => (
  <tr key={key} className="table__row">
    <td className="table__data">{message}</td>
    <td className="table__data">{formatDate(start)}</td>
    <td className="table__data">{formatDate(end)}</td>
  </tr>
);

const state = view(statusmeldingLens);

const Statusmelding = connect(state)(({ messages }) => (
  <Box title="Statusmelding">
    <div className="statusmelding">
      <table className="table statusmelding__table">
        <thead>
          <tr className="table__row">
            <th className="table__header">Melding</th>
            <th className="table__header">Start</th>
            <th className="table__header">Slutt</th>
          </tr>
        </thead>
        <tbody>{messages.map((message, i) => Melding(message, i))}</tbody>
      </table>
    </div>
  </Box>
));

export { Statusmelding, Melding };
