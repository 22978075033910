function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    console.error(response);
    return response.json().then(body => {
      const message = body.errors.map(e => e.message).join(', ');
      const error = new Error(message);
      error.response = response;
      throw error;
    });
    // const error = new Error(response.statusText);
    // error.response = response;
    // throw error;
  }
}

function customFetch(url, options) {
  return fetch(url, options).then(checkStatus);
}

async function post(url, body) {
  return customFetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      ...await getCsrfHeader(),
    },
    body: JSON.stringify(body),
    credentials: 'include'
  });
}

function get(url) {
  return customFetch(url, {
    method: 'GET',
    credentials: 'include'
  });
}

async function put(url, body) {
  return customFetch(url, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      ...await getCsrfHeader(),
    },
    body: JSON.stringify(body),
    credentials: 'include'
  });
}

async function getCsrfHeader() {
  return fetch("/api/csrf-token", {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.headerName && json.token) {
        return { [json.headerName]: json.token };
      }
      throw new Error('Missing CSRF-token');
    });
}

export { get, post, put };
