import React from 'react';
import './index.css';

const Box = ({ title, children }) => (
  <div className="box">
    <div className="box__title">{title}</div>
    <div className="box__container">
      <div className="box__content">{children}</div>
    </div>
  </div>
);

export { Box };
