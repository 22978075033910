import { combineReducers } from 'redux';
import { routerForBrowser } from 'redux-little-router';
import { statusmelding } from '/state/statusmelding';
import { user } from '/state/user';
import { forstegangsjeger } from '/state/forstegangsjeger';
import { flash } from '/flash/state';
import { info } from '/state/info';
import { discounts } from '/state/discount';
import { lensProp } from 'ramda';

const routes = {
  '/home': {
    title: 'Hjem',
    auth: true,
    request: {
      url: '/api/v1/admin/info',
      success: 'INFO_RESPONSE'
    },
    '/statusmelding': {
      title: 'Statusmelding',
      auth: true,
      request: { url: '/api/v1/admin/statusmelding', success: 'MESSAGES' }
    },
    '/forstegangsjeger': {
      title: 'Førstegangsjeger',
      auth: true,
      request: {
        url: '/api/v1/admin/innstillinger/forstegangsjeger',
        success: 'FORSTEGANGSJEGER_RESPONSE'
      }
    },
    '/discount': {
      title: 'Rabatter',
      auth: true,
      request: {
        url: '/api/v1/admin/discount',
        success: 'DISCOUNT_RESPONSE'
      },
      '/:id': {
        title: 'Kjøp',
        request: {
          url: '/api/v1/admin/kjop/:id',
          success: 'KJOP_RESPONSE'
        }
      }
    }
  }
};

const router = routerForBrowser({ routes });

const reducers = combineReducers({
  statusmelding,
  user,
  forstegangsjeger,
  flash,
  info,
  discounts,
  router: router.reducer
});

const lenses = {
  statusmelding: lensProp('statusmelding'),
  user: lensProp('user'),
  forstegangsjeger: lensProp('forstegangsjeger'),
  flash: lensProp('flash'),
  info: lensProp('info'),
  discounts: lensProp('discounts')
};

export { reducers, router, lenses };
