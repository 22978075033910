import { createReducer } from '/util/createReducer';
import { lensProp, set} from 'ramda';

const state = {
  messages: []
};

const statusmeldingLens = lensProp('statusmelding');
const messagesLens = lensProp('messages');

const reducers = {
  MESSAGES: (state, action) => set(messagesLens, action.payload, state)
};

const statusmelding = createReducer(state, reducers);

export { statusmelding, statusmeldingLens };
