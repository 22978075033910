import { createReducer } from '/util/createReducer';
import { lensProp, set, compose } from 'ramda';

const state = {
  environment: '',
  buildNumber: ''
};

const environment = lensProp('environment');
const buildNumber = lensProp('buildNumber');

const reducers = {
  INFO_RESPONSE: (state, action) =>
    compose(
      set(environment, action.payload.environment),
      set(buildNumber, action.payload.buildNumber)
    )(state)
};

const info = createReducer(state, reducers);

export { info };
