import React from 'react';
import { Sidebar } from '/sidebar';
import { Main } from '/main';

const LoggedIn = ({ children }) => (
  <React.Fragment>
    <Sidebar />
    <Main>{children}</Main>
  </React.Fragment>
);

export { LoggedIn };
